import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
         <path d="M1610 3539 c0 -5 35 -56 78 -112 52 -68 77 -109 74 -122 -4 -22 -64
-299 -137 -632 -25 -115 -62 -282 -82 -373 -19 -91 -43 -199 -53 -240 -10 -41
-16 -78 -13 -82 4 -7 793 -12 793 -5 0 2 20 97 45 211 25 115 45 215 45 223 0
11 -22 13 -117 11 l-117 -3 -14 -67 -15 -68 -51 -2 c-28 -1 -62 -2 -76 -2 -35
-1 -35 15 3 189 l33 150 87 5 87 5 139 103 c115 85 140 108 145 133 12 62 23
58 -192 61 l-197 3 34 158 34 157 80 0 80 0 -7 -37 c-3 -21 -9 -54 -12 -74
l-6 -36 95 -2 c147 -3 140 -8 166 116 l22 105 -86 113 c-47 62 -93 116 -100
119 -27 10 -765 6 -765 -5z"/>
<path d="M2515 3480 c25 -32 45 -63 45 -69 0 -11 12 -15 40 -12 12 2 12 0 2
-7 -12 -8 -12 -13 0 -31 7 -12 18 -19 24 -17 7 3 6 -13 -3 -47 -7 -29 -22 -95
-33 -147 -11 -52 -38 -178 -61 -280 -45 -204 -87 -401 -95 -440 -3 -14 -11
-52 -19 -85 -45 -188 -78 -355 -73 -361 2 -2 196 -5 430 -6 370 -3 425 -1 415
11 -10 12 -9 14 4 9 13 -5 16 -2 12 13 -3 10 -9 19 -15 19 -5 0 -3 6 4 14 7 8
18 11 23 6 6 -4 19 -10 30 -12 11 -3 28 -9 38 -13 10 -4 17 -3 17 3 0 6 -9 13
-19 17 -21 6 -67 75 -50 75 6 0 8 4 5 9 -3 4 2 16 12 24 16 15 22 14 67 -8 63
-31 82 -31 88 -2 2 12 3 7 1 -11 -3 -38 11 -34 19 5 3 16 17 82 31 148 14 66
26 123 26 127 0 4 18 28 40 53 22 26 47 59 55 75 17 33 20 96 5 105 -5 3 -10
0 -10 -7 0 -18 -40 -82 -47 -75 -3 3 0 21 7 39 6 18 16 60 20 93 5 33 15 89
23 125 23 99 66 296 82 375 8 39 24 111 35 160 24 104 18 132 -35 165 -31 19
-49 20 -609 20 l-577 -1 46 -59z m43 -25 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6
7 -7 10 -15 7 -18z m722 -221 c0 -8 -9 -56 -21 -107 -11 -50 -24 -112 -29
-137 -5 -25 -12 -54 -15 -65 -7 -30 -43 -200 -60 -285 -8 -41 -19 -88 -24
-105 -5 -16 -12 -42 -15 -56 l-6 -26 45 16 c27 9 43 21 41 28 -3 7 1 10 9 7
13 -6 47 9 147 64 32 17 64 29 73 26 18 -7 18 -9 3 -34 -10 -16 -10 -22 -1
-28 25 -16 -157 -129 -305 -191 -18 -7 -31 -15 -28 -18 8 -9 238 103 325 159
46 29 74 37 53 16 -14 -14 -16 -55 -4 -60 5 -1 3 -16 -4 -33 -7 -16 -13 -23
-14 -15 0 10 -3 11 -8 4 -18 -28 -250 -154 -284 -154 -6 0 -26 11 -45 26 -18
14 -37 23 -42 19 -12 -7 -51 47 -51 72 0 15 -2 16 -10 3 -5 -8 -10 -35 -10
-59 0 -35 -3 -41 -13 -32 -8 6 -16 9 -18 7 -2 -3 -45 -6 -94 -7 -82 -1 -90 0
-89 17 1 11 18 98 39 194 21 96 39 182 40 190 1 8 5 22 8 30 3 8 8 29 11 45 5
27 18 86 86 400 12 55 26 102 30 105 4 3 69 4 144 2 111 -3 136 -6 136 -18z
m357 -76 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m-1030 -10
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1010 0 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-370 -175 c-3 -10 -5 -2 -5 17 0 19 2
27 5 18 2 -10 2 -26 0 -35z m20 10 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m-360 -65 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m350 -15 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m310 -25 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1020 -20 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m987 -14 c3 -8 2 -12 -4 -9 -6 3 -10 10
-10 16 0 14 7 11 14 -7z m-997 -36 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m1005 -50 c-6 -6 -7 0 -4 19 5 21 7 23 10 9 2 -10 0 -22 -6 -28z
m-1025 -50 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m670 -10 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-10 -40 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-336 -34 c-5 -16 -10 -21 -10 -12 -1 18
10 51 15 45 2 -1 -1 -16 -5 -33z m-24 -16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m370 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-357 -44 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m327 -16
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m343 -72 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-725 -226 c3 -5
1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-408 -222
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m820 -14 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M3441 2460 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
<path d="M3310 2010 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3230 1950 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M1423 1803 l-43 -4 0 -85 0 -85 58 3 c51 3 57 5 60 27 3 21 1 23 -32
19 -46 -5 -47 8 -1 16 26 5 35 12 35 26 0 16 -7 20 -35 20 -19 0 -35 5 -35 10
0 6 16 10 35 10 31 0 35 3 35 25 0 18 -5 24 -17 23 -10 -1 -37 -3 -60 -5z"/>
<path d="M1557 1799 c-23 -13 -47 -62 -47 -94 0 -35 42 -75 79 -75 39 0 79 36
87 78 l7 32 -47 0 c-39 0 -46 -3 -46 -20 0 -12 7 -20 18 -20 14 0 14 -2 3 -9
-23 -15 -42 -1 -39 28 2 21 9 27 35 29 36 4 58 24 49 47 -6 17 -71 20 -99 4z"/>
<path d="M1719 1781 c-40 -40 -41 -100 -3 -130 38 -30 71 -26 110 13 38 38 45
85 18 124 -24 34 -87 31 -125 -7z m84 -43 c7 -20 -12 -48 -33 -48 -24 0 -33
15 -25 40 7 22 50 28 58 8z"/>
<path d="M1883 1803 c-9 -3 -13 -31 -13 -89 l0 -84 28 0 c86 0 137 41 108 85
-10 15 -12 26 -6 30 15 9 12 31 -6 49 -15 15 -83 21 -111 9z m65 -45 c-6 -18
-28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m7 -68 c-3 -5 -13 -10 -21
-10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z"/>
<path d="M2073 1803 l-43 -4 0 -85 0 -84 55 0 c49 0 57 3 64 23 8 20 5 22 -33
27 l-41 5 38 8 c27 6 37 13 37 27 0 16 -7 20 -37 20 -28 0 -34 3 -23 10 8 5
25 10 38 10 17 0 22 6 22 25 0 18 -5 24 -17 23 -10 -1 -37 -3 -60 -5z"/>
<path d="M2177 1803 c-4 -3 -7 -44 -7 -91 l0 -85 38 7 c20 3 47 6 60 6 17 0
22 6 22 25 0 22 -4 25 -35 25 l-35 0 0 60 c0 52 -3 60 -18 60 -10 0 -22 -3
-25 -7z"/>
<path d="M2307 1803 c-4 -3 -7 -44 -7 -91 l0 -85 38 7 c20 3 47 6 60 6 17 0
22 6 22 25 0 22 -4 25 -35 25 l-35 0 0 60 c0 52 -3 60 -18 60 -10 0 -22 -3
-25 -7z"/>
<path d="M2607 1803 c-15 -4 -17 -15 -15 -81 l3 -77 30 -3 c41 -3 84 33 91 78
5 28 1 40 -20 63 -24 26 -47 31 -89 20z m61 -45 c15 -15 16 -54 2 -63 -6 -4
-8 -10 -5 -15 3 -5 0 -12 -6 -16 -8 -5 -9 -3 -4 6 6 10 4 12 -8 7 -18 -7 -27
11 -27 52 0 41 22 55 48 29z m-61 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z"/>
<path d="M2781 1773 c-13 -47 -14 -63 -1 -43 6 9 10 10 10 3 0 -7 -6 -16 -13
-20 -16 -9 -40 -73 -28 -73 5 0 12 11 15 25 4 14 12 25 18 25 9 0 9 -3 0 -12
-7 -7 -12 -19 -12 -27 0 -12 3 -12 12 -3 7 7 20 12 30 12 10 0 18 6 19 13 0 6
4 2 9 -10 9 -23 34 -31 44 -14 3 5 -5 42 -18 82 -21 64 -27 74 -49 77 -22 3
-27 -2 -36 -35z m62 -52 c4 8 6 6 4 -6 -1 -12 -11 -21 -25 -23 -18 -3 -21 1
-18 20 3 13 5 28 6 33 0 6 7 -1 14 -14 10 -18 15 -20 19 -10z m19 -43 c-7 -7
-12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z"/>
<path d="M3018 1804 c-4 -3 -27 -6 -52 -6 -37 0 -46 -3 -46 -17 0 -26 10 -32
34 -19 11 6 18 7 14 3 -4 -5 -3 -14 3 -22 6 -7 7 -13 3 -13 -5 0 -8 -20 -6
-45 3 -36 8 -45 23 -45 15 0 18 9 21 62 3 52 6 63 23 68 27 7 32 40 7 40 -10
0 -21 -3 -24 -6z m2 -18 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25
6z"/>
<path d="M3132 1802 c-7 -5 -14 -23 -15 -40 -1 -18 -5 -32 -8 -32 -11 0 -20
-83 -11 -92 6 -6 16 -3 25 6 18 18 53 21 62 6 7 -12 16 -12 33 -1 14 8 5 46
-30 134 -10 27 -31 34 -56 19z m25 -24 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m20 -30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-7 -52 c-5 -5 -15 -6 -24 -3 -12 5 -13 10 -3 31 l12 25 12 -22 c6 -12 8 -26
3 -31z m23 -11 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m-68
-5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
